import { AxiosResponse } from 'axios'
import { http } from '../api'
import { format } from 'date-fns'
const controller = 'previews'

export async function getPaginate(page: number, size: number, start_date?: Date, end_date?: Date): Promise<any> {
    const url = `${controller}/?page=${page}&page_length=${size}${start_date ? `&start_date=${format(start_date, 'yyyy-MM-dd')}` : ''}${end_date ? `&end_date=${format(end_date, 'yyyy-MM-dd')}` : ''}`;
    const { data } = await http.get<any, AxiosResponse>(url);
    return data;
}

export async function getUser(userId: number, start_date?: Date, end_date?: Date): Promise<any> {
    const url = `${controller}/user/${userId}?u=u${start_date ? `&start_date=${format(start_date, 'yyyy-MM-dd')}` : ''}${end_date ? `&end_date=${format(end_date, 'yyyy-MM-dd')}` : ''}`;
    const { data } = await http.get<any, AxiosResponse>(url);
    return data;
}

export async function getUserFutureComission(userId: number, page: number, size: number, is_export?: boolean): Promise<any>
{
    const url = `${controller}/user/future-comissions/${userId}?page=${page}&page_length=${size}${is_export ? '&export=true' : ''}`;
    const { data } = await http.get<any, AxiosResponse>(url);
    return data;
}

// Busca o valor (dinheiros) da comissão futura
export async function getUserFutureComissionValue(userId: number | undefined): Promise<any>
{
    if(userId)
    {
        const url = `${controller}/user/future-comissions-value/${userId}`;
        const { data } = await http.get<any, AxiosResponse>(url);
        return data;
    }
    
    return 0;
}

export async function getUserComissionsAvailable(userId: number, page: number, size: number, start_date?: Date, end_date?: Date, is_export?: boolean): Promise<any>
{
    const url = `${controller}/user/comissions-available/${userId}?page=${page}&page_length=${size}${start_date ? `&start_date=${format(start_date, 'yyyy-MM-dd')}` : ''}${end_date ? `&end_date=${format(end_date, 'yyyy-MM-dd')}` : ''}${is_export ? '&export=true' : ''}`;
    const { data } = await http.get<any, AxiosResponse>(url);
    return data;
}

export async function getBy(id: string): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/${id}`)
    return data;
}