import { Authentication } from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../pages/login/loginPage.vue'),
  },
  {
    path: '/login-cliente',
    name: 'Login Cliente',
    component: () => import('../pages/login/loginCliente.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../pages/dashboard/DashboardPage.vue'),
    meta: {
      icon: 'HouseIcon',
      divider: true,
    },
  },
  {
    path: '/my-profile',
    name: 'Meu Perfil',
    component: () => import('../pages/dashboard/DashboardPage.vue'),
    meta: {
      icon: 'HouseIcon',
      divider: true,
    },
  },
  {
    path: '/goal',
    name: 'Metas',
    component: () => import('../pages/goal/GoalPage.vue'),
    meta: {
      icon: 'CompanyIcon',
    },
  },
  {
    path: '/goal-register',
    name: 'CadastrarMetas',
    component: () => import('../pages/goal/components/GoalRegister.vue'),
    meta: {
      icon: 'CompanyIcon',
    },
  },
  {
    path: '/grades',
    name: 'Notas',
    component: () => import('../pages/grades/GradesPage.vue'),
    meta: {
      icon: 'PeopleIcon',
    },
  },
  {
    path: '/collaborators',
    name: 'Colaboradores',
    component: () => import('../pages/collaborators/CollaboratorsPage.vue'),
    meta: {
      icon: 'GroupIcon',
    },
  },
  {
    path: '/settings',
    name: 'Configurações',
    component: () => import('../pages/settings/SettingsPage.vue'),
    meta: {
      icon: 'MonitorMobbileIcon',
    },
  },
  {
    path: '/reports',
    name: 'Relatórios',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      icon: 'MonitorMobbileIcon',
    },
  },
  {
    path: '/profile',
    name: 'Meus Dados',
    component: () => import('../pages/profile/ProfileData.vue'),
    meta: {
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  Authentication.actions.ActionCheckToken();
  const token = Authentication.getters.getUser.value.token;

  const isMasterOrAdmin = Authentication.getters.isMaster.value || Authentication.getters.isAdmin.value;
  if (!token && to.name !== 'Login') {
    next({ name: 'Login' });
  } else if (token && to.path == "/") {

    if (isMasterOrAdmin) {
      next("/collaborators")
    }
    else {
      next("/dashboard")
    }
  } else if (to.path != '/' && to.path != '/profile') {

    if (Authentication.getters.isMaster.value)
    {
      if (to.path == '/my-profile') {
        next('/collaborators');
      } else
        next(true)
    }
    else if (Authentication.getters.isAdmin.value)
    {
      // if (to.path == '/dashboard') {
      //   next('/my-profile');
      // } else
        next(true)
    }
    else if (!isMasterOrAdmin) {
      if (to.path == '/grades' || to.path == '/' || to.path == '/settings' || to.path == '/my-profile') {
        next('/dashboard');
      } else
        next(true)
    }
    else next(true);
  } else next(true);

});
export { routes };
export default router;
