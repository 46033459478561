import { AxiosResponse } from 'axios'
import { http } from '../api'
import { Role } from '../model'

const controller = 'roles'

export async function getPaginate(page?: number, size?: number, name?: string, isExport?: boolean): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/?u=u${page ? `&page=${page}` : ''}${size ? `&page_length=${size}` : ''}${(name ? `&name=${name}` : '')}${isExport ? `&export=true` : ''}`);
    return data;
}

export async function get(): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/`);
    return data;
}


export async function getBy(id: string): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function create(payload: Role): Promise<Role> {

    const { data } = await http.post<Role, AxiosResponse>(`${controller}/`, payload)
    return data;
}

export async function update(id: number, payload: Role): Promise<Role> {

    const { data } = await http.put<Role, AxiosResponse>(`${controller}/${id}`, payload);
    return data;
}

export async function deleted(id: number): Promise<boolean> {

    const { status } = await http.delete(`${controller}/${id}`);

    return status === 204;
}