import { AxiosResponse } from 'axios'
import { http } from '../api'

import { Credential } from '../model'
import { LoginResponse } from '../model/LoginResponse';





export async function login(payload: Credential): Promise<LoginResponse> {
  const controller = 'login'
  const { data } = await http.post<LoginResponse, AxiosResponse>(`${controller}`, {
    username: payload.email,
    password: payload.password
  });
  return data;
}


// export async function resetPassword(email: string): Promise<boolean> {
//   const controller = 'auth/reset_password'
//   const { status, data } = await http.post<any, AxiosResponse>(`${controller}`, {
//     email
//   });
//   return status == 204;
// }


