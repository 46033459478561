import { AxiosResponse } from 'axios'
import { http } from '../api'
import { format } from 'date-fns'
const controller = 'invoices'

export async function getPaginate(page?: number, size?: number, start_date?: Date, end_date?: Date, filters?: any, isExport?: boolean): Promise<any> {


    let filter = '';


    if (filters) {

        Object.keys(filters).map(x => {
            if (filters[x] && x != "start_date" && x != "end_date")
                filter = `${filter}&${x}=${filters[x]}`;

        })
    }
    const url = `${controller}/?u=u${page ? `&page=${page}` : ''}${size ? `&page_length=${size}` : ''}${start_date ? `&start_date=${format(start_date, 'yyyy-MM-dd')}` : ''}${end_date ? `&end_date=${format(end_date, 'yyyy-MM-dd')}` : ''}${filter ? filter : ''}${isExport ? `&export=true` : ''}`;
    const { data } = await http.get<any, AxiosResponse>(url);
    return data;
}
export async function getUser(userId: number, page?: number, size?: number, start_date?: Date, end_date?: Date, filters?: any, isExport?: boolean): Promise<any> {
    let filter = '';
    if (filters) {

        Object.keys(filters).map(x => {
            if (filters[x] && x != "start_date" && x != "end_date")
                filter = `${filter}&${x}=${filters[x]}`;

        })
    }
    const url = `${controller}/user/${userId}?u=u${page ? `&page=${page}` : ''}${size ? `&page_length=${size}` : ''}${start_date ? `&start_date=${format(start_date, 'yyyy-MM-dd')}` : ''}${end_date ? `&end_date=${format(end_date, 'yyyy-MM-dd')}` : ''}${filter ? filter : ''}${isExport ? `&export=true` : ''}`;
    const { data } = await http.get<any, AxiosResponse>(url);
    return data;
}
export async function getBy(id: string): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/${id}`)
    return data;
}
export async function getMonthQuotations(page?: number, size?: number): Promise<any>
{
    const { data } = await http.get<any, AxiosResponse>(`${controller}/month-quotations?u=u${page ? `&page=${page}` : ''}${size ? `&page_length=${size}` : ''}`);
    return data;
}
export async function updateMonthQuotations(payload: { invoices_id: number[], month: Date | undefined }): Promise<any>
{
    const { data } = await http.post<any, AxiosResponse>(`${controller}/month-quotations`, payload);
    return data;
}