import { reactive, computed, ref } from "vue";

const goals_id = ref<number>(0);

const state = reactive<{ loading: boolean }>({
  loading: false,
});

const actions = {
  ActionSetPreloader(payload: boolean) {
    state.loading = payload;
  },
  SetGoalId(payload: number)
  {
    goals_id.value = payload;
  }
};

const getters = {
  isLoading: computed(() => state.loading),
  goalId: computed(() => goals_id.value),
};
export { getters, state, actions };
