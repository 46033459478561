// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#17CF97',
    'primary-darken-1': '#3700B3',
    secondary: '#006EE2',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
   'gray-500': '#697B8E',
    warning: '#FB8C00',
    tertiary: '#F6F7F9',
    'tertiary-darken-1': '#D6DBE1',
    quaternary: '#D6DBE1',
    'quaternary-darken-1': '',
  },
};

export default createVuetify({
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
        }
    }
})


