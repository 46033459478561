import axios from 'axios'
import { Authentication } from '../../store'
import Swal from 'sweetalert2'
import router from "@/router"

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

http.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return
  if (Authentication.state.value.token) {
    config.headers.Authorization = `Bearer ${Authentication.state.value.token}`
  }

  return config
})

const errors = {
  'invalid_credentials': 'Credenciais Inválidas',
  'brand_not_found': 'Marca não encontrada',
  'brand_create_success': 'Marca criada com sucesso',
  'brand_create_fail': 'Erro ao criar marca',
  'brand_update_success': 'Marca atualizada com sucesso',
  'brand_update_fail': 'Erro ao atualizar marca',
  'brand_delete_success': 'Marca excluída com sucesso',
  'brand_delete_fail': 'Erro ao excluir marca',
  'missing_required_fields': 'Campos obrigatórios não preenchidos',
  'configuration_not_found': 'Configuração não encontrada',
  'configuration_create_success': 'Configuração criada com sucesso',
  'configuration_create_fail': 'Erro ao cadastrar configuração',
  'configuration_update_success': 'Configuração atualizada com sucesso',
  'configuration_update_fail': 'Erro ao atualizar configuração',
  'not_allowed': 'Não permitido',
  'start_date_greater_than_end_date': 'Data inicial maior que a data final',
  'error_consulting_database': 'Erro ao consultar o banco de dados',
  'goal_not_found': 'Meta não encontrada',
  'goal_required_field_missing': 'Campos obrigatórios não preenchidos na meta',
  'resale_not_found': 'Revenda não encontrada',
  'warehouse_not_found': 'Depósito não encontrado',
  'trigger_item_not_found': 'Item do Gatilho não encontrado',
  'triger_item_value_not_defined': 'Valor do Item do Gatilho não definido',
  'clearance_sale_reward_not_defined': 'Valor da recompensa do Saldão não definido',
  'goal_create_fail': 'Erro ao criar meta',
  'goal_create_success': 'Meta criada com sucesso',
  'reason_required': 'Motivo é obrigatório',
  'goal_update_success': 'Meta atualizada com sucesso',
  'goal_update_fail': 'Erro ao atualizar Meta',
  'users_id should not be null or undefined': 'Usuário não deve ser nulo ou indefinido',
  'roles_id should not be null or undefined': 'Função não deve ser nulo ou indefinido',
  'seasons_id should not be null or undefined': 'Sazonalidade não deve ser nulo ou indefinido',
  'performant should not be null or undefined': 'Performante não deve ser nulo ou indefinido',
  'goal_lines should not be null or undefined': 'As Linhas das metas não deve ser nulo ou indefinido',
  'goal_user_not_found': 'Colaborador não encontrado',
  'goal_role_not_found': 'Função não encontrado',
  'goal_seller_not_found': 'Vendedor não encontrado',
  'goal_season_not_found': 'Sazonalidade não encontrada',
  'goal_already_exists': 'Meta já existe',
  'invoice_not_found': 'Fatura não encontrada',
  'item_not_found': 'Item não encontrada',
  'performant_not_found': 'Performante não encontrada',
  'preview_not_found': 'Prévia não encontrada',
  'provision_not_found': 'Provisão não encontrada',
  'reception_not_found': 'Recepção não encontrada',
  'resale_create_success': 'Revenda criada com sucesso',
  'resale_create_fail': 'Erro ao criar revenda',
  'resale_update_success': 'Revenda atualizada com sucesso',
  'resale_update_fail': 'Erro ao atualizar revenda',
  'resale_delete_fail': 'Erro ao excluir revenda',
  'resale_delete_success': 'Revenda excluída com sucesso',
  'role_not_found': 'Função não encontrada',
  'role_create_success': 'Função criada com sucesso',
  'role_create_fail': 'Erro ao criar função',
  'role_update_success': 'Função atualizada com sucesso',
  'role_update_fail': 'Erro ao atualizar função',
  'role_delete_fail': 'Erro ao excluir função',
  'role_delete_success': 'Função excluída com sucesso',
  'season_not_found': 'Sazonalidade não encontrada',
  'season_hundred_error': 'O somatório dos pesos da sazonalidade precisa ser 100%',
  'season_create_success': 'Sazonalidade criada com sucesso',
  'season_create_fail': 'Erro ao criar sazonalidade',
  'season_delete_fail': 'Erro ao excluir sazonalidade',
  'season_delete_success': 'Sazonalidade excluída com sucesso',
  'trigger_not_found': 'Gatilho não encontrado',
  'trigger_items_not_set': 'Os intervalos do gatilho não foram preenchidos',
  'trigger_item_value_error': 'Erro na passaem de valor dos itens do gatilho',
  'trigger_item_hundred_error': 'O somatório dos itens do gatilho precisa ser 100%',
  'trigger_item_create_fail': 'Erro ao criar Itens do gatilho',
  'trigger_create_success': 'Gatilho criado com sucesso',
  'trigger_create_fail': 'Erro ao criar gatilho',
  'trigger_item_delete_fail': 'Erro ao excluir o item do gatilho',
  'trigger_delete_fail': 'Erro ao excluir o gatilho',
  'trigger_delete_success': 'Gatilho excluído com sucesso',
  'user_not_found': 'Usuário não encontrado',
  'user_create_success': 'Usuário criado com sucesso',
  'user_create_fail': 'Erro ao criar usuário',
  'user_update_success': 'Usuário atualizado com sucesso',
  'user_update_fail': 'Erro ao atualizar usuário',
  'user_delete_success': 'Usuário excluído com sucesso',
  'user_delete_fail': 'Erro ao excluir usuário',
  'preference_not_set': 'Preferência não setada',
  'user_preference_success': 'Usuário da preferência não setado',
  'user_preference_fail': 'Erro ao  criar Usuário da preferência',
  'warehouse_create_success': 'Depósito criado com sucesso',
  'warehouse_create_fail': 'Erro ao criar depósito',
  'warehouse_update_success': 'Depósito atualizado com sucesso',
  'warehouse_update_fail': 'Erro ao atualizar depósito',
  'warehouse_delete_success': 'Depósito excluído com sucesso',
  'warehouse_delete_fail': 'Erro ao excluir depósito',
  'unauthorized': 'Não autorizado',
  'Internal Server Error': 'Ops..Ocorreu um erro inesperado no sistema, por favor contacte o suporte',
  'only_image_allowed': 'Somente arquivos do tipo imagem são permitidos',
  'resale_already_exists': 'Revenda já existe',
  'sap_users_id': 'O campo "Registro no SAP" não pode ser vazio',
  'name': 'O campo "Nome" não pode ser vazio',
  'sap_card_code': 'O campo "Código da Revenda" não pode ser vazio',
  'sap_card_name': 'O campo "Nome" não pode ser vazio',
  'sap_aidc_brand_code': 'O campo "Código no SAP" não pode ser vazio',
  'sap_code': 'O campo "Código no SAP" não pode ser vazio',
  'company': 'O campo "Base do Depósito" não pode ser vazio',
  'start_date': 'O campo "Código no SAP" não pode ser vazio',
  'end_date': 'O campo "Código no SAP" não pode ser vazio',
  'jan': 'O campo "Janeiro" não pode ser vazio',
  'feb': 'O campo "Fevereiro" não pode ser vazio',
  'mar': 'O campo "Março" não pode ser vazio',
  'apr': 'O campo "Abril" não pode ser vazio',
  'may': 'O campo "Maio" não pode ser vazio',
  'jun': 'O campo "Junho" não pode ser vazio',
  'jul': 'O campo "Julho" não pode ser vazio',
  'aug': 'O campo "Agosto" não pode ser vazio',
  'sep': 'O campo "Setembro" não pode ser vazio',
  'oct': 'O campo "Outubro" não pode ser vazio',
  'nov': 'O campo "Novembro" não pode ser vazio',
  'dec': 'O campo "Dezembro" não pode ser vazio',
  'brands_required': 'Informe a marca a ser adicionada',
  'brand_already_registered': 'Marca já cadastrada na meta',
  'invoices_required': 'Selecione pelo menos uma nota',
  'new_date_required': 'Informe o novo mês para a nota',
  'invoice_update_fail': 'Erro ao atualizar a nota'
}

http.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    let message = error?.response?.data?.message;
    if(Array.isArray(error?.response?.data))
    {
      message = error?.response?.data[0].field;
    }
    if (error.response.status === 401) {

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    } else if ([400, 404, 406].includes(error.response.status)) {
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: errors[message] ?? message
      });
    } else if (error.response.status === 500) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: errors[message] ?? message ?? "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
      });
    }

    return Promise.reject(error)
  },
)

export { http }