export const formatNumber = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

export const formatLang = (value: number): string => {
    return new Intl.NumberFormat('pt-BR').format(value);
}

export const extrairNumerosMoedaBRL = (str:string) => {
    const numerosString = str.replace(/[^\d,]/g, '');
  
    const resultado = parseFloat(numerosString.replace(',', '.'));
  
    return isNaN(resultado) ? null : resultado;
}

export const formatPercentage = (value: number, fixed = 2) => {
    return Number(value).toFixed(fixed).replace('.', ',') + "%";
}

export const formatCurrency = (number, fixed = 2) => {
    // Formatar o número para a moeda brasileira (BRL)
    const formattedCurrency = Number(number).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: fixed
    });
    return formattedCurrency;
}

export function formatTriggerItemPercentageLabel(start_value: number, end_value: number)
{
    return `${Number(start_value).toFixed(0) == "0" ? (Number(start_value).toFixed(0) + ",00") : (Number(start_value).toFixed(0) + ",01") + "%"} - ${formatPercentage(Number(end_value))}`;
}
  