import { AxiosResponse } from 'axios'
import { http } from '../api'
import { User } from '../model'

const controller = 'users'

export async function getPaginate(page?: number, size?: number, search?: string, common?: boolean, roleId?: number, isExport?: boolean): Promise<any> {
  const { data } = await http.get<any, AxiosResponse>(`${controller}/?u=u${page ? `&page=${page}` : ''}${size ? `&page_length=${size}` : ''}${(search ? `&search=${search}` : '')}${(common ? `&common=${common}` : '')}${roleId ? `&role_id=${roleId}` : ''}${isExport ? `&export=true` : ''}`);
  return data;
}
export async function ldap(): Promise<any> {
  const { data } = await http.get<any, AxiosResponse>(`${controller}/ldap-users`)
  return data;
}
export async function ldapGoAhead(): Promise<any> {
  const { data } = await http.get<any, AxiosResponse>(`${controller}/ldap-goahead-users`)
  return data;
}

export async function sap(): Promise<any> {
  const { data } = await http.get<any, AxiosResponse>(`${controller}/sap-codes`)
  return data;
}

export async function getBy(id: string): Promise<any> {
  const { data } = await http.get<any, AxiosResponse>(`${controller}/${id}`)
  return data;
}

export async function getByProfile(): Promise<any> {
  const { data } = await http.get<any, AxiosResponse>(`${controller}/profile`)
  return data;
}

export async function create(payload: User): Promise<User> {
  const { data } = await http.post<User, AxiosResponse>(`${controller}/`, payload)
  return data;
}

export async function update(id: number, payload: User): Promise<User> {
  const { data } = await http.put<User, AxiosResponse>(`${controller}/${id}`, payload);
  return data;
}

export async function update_form_data(id: number, payload: FormData): Promise<User> {
  const { data } = await http.put<User, AxiosResponse>(`${controller}/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return data;
}

export async function deleted(id: number): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  return status === 204;
}

export async function getAll(search?: string, common?: boolean, paged?: boolean, page?: number, size?: number, isExport?: boolean): Promise<any> {
  const { data } = await http.get<any, AxiosResponse>(`${controller}/get-users?u=u${(search ? `&search=${search}` : '')}${(common ? `&common=${common}` : '')}${(paged ? `&paged=${paged}` : '')}${page ? `&page=${page}` : ''}${size ? `&page_length=${size}` : ''}${isExport ? `&export=true` : ''}`);
  return data;
}