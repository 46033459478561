import { format } from 'date-fns'

export function formatDateToString(date: Date | undefined, toFormat = 'dd/MM/yyyy')
{
    if(date)
    {
        return format(new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate() + 1), toFormat);
    }

    return '-';
}