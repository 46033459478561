<template>
    <svg width="106" height="100" viewBox="0 0 106 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" fill="url(#paint0_linear_82_3817)" />
        <path
            d="M55.0912 28.334H44.9078C42.6545 28.334 40.8128 30.154 40.8128 32.4073V34.444C40.8128 36.6973 42.6328 38.5173 44.8862 38.5173H55.0912C57.3445 38.5173 59.1645 36.6973 59.1645 34.444V32.4073C59.1862 30.154 57.3445 28.334 55.0912 28.334Z"
            fill="white" />
        <path
            d="M61.3534 34.4435C61.3534 37.8885 58.5368 40.7052 55.0918 40.7052H44.9084C41.4634 40.7052 38.6468 37.8885 38.6468 34.4435C38.6468 33.2302 37.3468 32.4719 36.2634 33.0352C33.2084 34.6602 31.1284 37.8885 31.1284 41.5935V61.9819C31.1284 67.3119 35.4834 71.6669 40.8134 71.6669H59.1867C64.5168 71.6669 68.8717 67.3119 68.8717 61.9819V41.5935C68.8717 37.8885 66.7917 34.6602 63.7367 33.0352C62.6534 32.4719 61.3534 33.2302 61.3534 34.4435ZM55.4817 60.1619C55.1567 60.4869 54.7451 60.6385 54.3334 60.6385C53.9217 60.6385 53.5101 60.4869 53.1851 60.1619L50.0434 57.0202L46.8151 60.2485C46.4901 60.5735 46.0784 60.7252 45.6667 60.7252C45.2551 60.7252 44.8434 60.5735 44.5184 60.2485C43.8901 59.6202 43.8901 58.5802 44.5184 57.9519L47.7467 54.7235L44.6051 51.5819C43.9768 50.9535 43.9768 49.9135 44.6051 49.2852C45.2334 48.6569 46.2734 48.6569 46.9017 49.2852L50.0434 52.4269L53.0767 49.3935C53.7051 48.7652 54.7451 48.7652 55.3734 49.3935C56.0017 50.0219 56.0017 51.0619 55.3734 51.6902L52.3401 54.7235L55.4817 57.8652C56.1101 58.4935 56.1101 59.5119 55.4817 60.1619Z"
            fill="white" />
        <circle cx="91" cy="18" r="15" fill="#FFAA00" />
        <path d="M91 24.322L91 24.334M91 12.334L91 20.734" stroke="white" stroke-width="2.72727" stroke-linecap="round"
            stroke-linejoin="round" />
        <defs>
            <linearGradient id="paint0_linear_82_3817" x1="45" y1="45" x2="107.692" y2="116.704"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#17CF97" />
                <stop offset="1" stop-color="#A9F1D2" />
            </linearGradient>
        </defs>
</svg></template>
<script lang="ts" setup>
</script>