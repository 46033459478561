import { Goal } from './core/model/Goal';


// export interface IUser {
//     name: string
//     age: number
//     token: string
//     avatar?: string
// }

export interface IBrandsDetailMeta {
    image?: string
    name?: string
}
export interface IMetasTooltip {
    role?: string
    name?: string
    margin?: string
    percent?: string
}
export interface IMetasOnlyTooltip {
    goal: string
    margin?: string
    percent?: string
}
export interface ISeasonality {
    percentage?: string
    value?: string
    month?: string
}

export interface IItemField {
    label: string
    field: string
}

export interface IProgressPhased {
    percent: number;
    max: number
    progressColor?: string;
    icon?: string;
}

export interface IDataCommission {
    text: string;
    value: string;
}
export interface IDataCommission {
    text: string;
    value: string;
}
export interface IBrands {
    url: string;
    name: string
}
export interface IGoal {
    name: string;
    row: IDataCommission[],
    orientation: IBrands[],
    email: string;
    iconBrand: string;
    badgeText: string
    badgeColor: string
    badgeTextColor: string
    progress?: number
    goal: Goal
}

export interface IDataMapChart {
    uf: string;
    value: number;
}

export interface IDataChart {
    title: string;
    value: number;
    color?: string
}

export interface IDataChartMore {
    color: string;
    name: string;
    data: IDataChart[];
}

export interface ISvgIcon {
    svg: string;
    size?: Size;
}

export type InputType = 'email' | 'number' | 'password' | 'search' | 'date' | 'tel' | 'url' | 'text' | 'color' | 'time' | 'month';


export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full'

export type ImageSize = Size | 'cover' | 'biggest'

export type PanelSize = 'extra-big' | 'big' | 'medium' | 'small'

export interface IItemTreeTable {
    key: string;
    data: any;
    children?: Children[];
}

export interface Children {
    key: string;
    data: any;
}


export interface IDataCommission {
    text: string;
    value: string;
}

export interface IOrientationGoal {
    url: string;
    name: string;
}
export interface IHeadersTable {
    extra?: any;
    label: string;
    field: string;
    orderItem?: number;
    type?: string;
    dateInputFormat?: string;
    dateOutputFormat?: string;
    mode?: string;
    update?: string;
    options?: IOptionsSelect[];
    expander?: boolean;
    checked?: boolean;
    sortable?: boolean;
    maxLines?: number;
}

export interface ISidebarLink {
    name: string
    nameRoute: string
    icon: string
    children?: ISidebarLink[]
}
export interface IDataPolarChart {
    label: string;
    data: Array<number>;
    backgroundColor: string,
    borderColor: string,
    pointBackgroundColor?: string,
    pointBorderColor?: string,
    pointHoverBackgroundColor?: string,
    pointHoverBorderColor?: string
}


export interface IDataInfo {
    number: string | number
    description: string
    icon?: string
    noBorder?: boolean
    order?: number
}
export interface IToggleButon {
    text: string;
    value: string;
    select?: boolean;
}
export interface ITab {
    label: string;
    value: string;
    icon?: string;
}
export interface IItemDiagnostic {
    item: string;
    icon: string;
    value: number;
    nFloat: number;
    isPositive: boolean,
    color?: string;
    iconColor?: string;
    id?: string;
    loading?: boolean;
}
export interface IProgress {
    hasPercentTitle: boolean;
    hasPercent: boolean;
    title?: string | undefined;
    value: number;
    color: string;
    maxValue: number;
}


export interface IOverlayImage {
    url: string
    data: any
}
export interface IDataFinanceInfo {
    value: string | number
    description: string
}
export interface IOptionsSelect {
    value: string | number | boolean | Date
    text: string
    selected?: boolean
}

export interface IOptionsSelectFlag {
    value: string
    text: string
    selected?: boolean
}
export interface IMonthYear {
    month?: number | undefined;
    year?: number | undefined;
}

export interface IStep {
    label: string;
    completed: boolean
}

export interface IDataset {
    label: string;
    data: any;
    borderColor?: string;
    backgroundColor?: string;
    borderWidth?: number;
    borderRadius?: number;
    borderSkipped?: boolean;
    pointStyle?: string;
}
export interface IOptions {
    scales?: any;
    plugins?: any;
}



export interface IMenuItem {
    name: string;
    path: string;
    icon?: string;
    divider?: boolean;
}

export const icons = 
{
    welcome: '/assets/icons/welcome.svg',
    general: '/assets/icons/general.svg',
    values: '/assets/icons/values.svg',
    margin: '/assets/icons/margin.svg',
    futureComission: '/assets/icons/future-comission.svg',
    availableComission: '/assets/icons/available-comission.svg',
    progress: '/assets/icons/progress.svg',
    filter: '/assets/icons/filter.svg',
    goal: '/assets/icons/goal.svg',
    detail: '/assets/icons/detail.svg',
    resume: '/assets/icons/resume.svg',
    performing: '/assets/icons/performing.svg',
    search: '/assets/icons/search.svg',
    dashboardNavigation: '/assets/icons/dashboard-navigation.svg',
    goalNavigation: '/assets/icons/goal-navigation.svg',
    logout: '/assets/icons/logout.svg',
    profile: '/assets/icons/profile.svg',
    language: '/assets/icons/language.svg',
    tutorial: '/assets/icons/tutorial.svg',
    invoice: '/assets/icons/invoice.svg',
    finished: '/assets/icons/finished.svg',
}

export const NavigationIconState = {
    default: '#8897A8',
    hover: '#17CF9780',
    active: '#17CF97'
}

export type Source = "resale" | "brand" | "warehouse" | "trigger" | "user" | "season";

export interface PageData {
  source: Source
  page: number,
  enableToLoad: boolean
}