import { AxiosResponse } from 'axios'
import { http } from '../api'
import { Resale } from '../model'

const controller = 'resales'

export async function getPaginate(page?: number, size?: number, search?: string, isExport?: boolean): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/?u=u${page ? `&page=${page}` : ''}${size ? `&page_length=${size}` : ''}${(search ? `&search=${search}` : '')}${isExport ? `&export=true` : ''}`);
    return data;
}


export async function getBy(id: string): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function create(payload: Resale): Promise<Resale> {
    const { data } = await http.post<Resale, AxiosResponse>(`${controller}/`, payload)
    return data;
}

export async function update(id: number, payload: Resale): Promise<Resale> {
    const { data } = await http.put<Resale, AxiosResponse>(`${controller}/${id}`, payload);
    return data;
}

export async function deleted(id: number): Promise<boolean> {
    const { status } = await http.delete(`${controller}/${id}`);
    return status === 204;
}