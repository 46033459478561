import { AxiosResponse } from 'axios'
import { http } from '../api'
import { Warehouse } from '../model'

const controller = 'warehouses'

export async function getPaginate(page?: number, size?: number, search?: string, isExport?: boolean): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/?u=u${page ?`&page=${page}`:'' }${size? `&page_length=${size}`:''}${(search ? `&search=${search}` : '')}${isExport ? `&export=true` : ''}`);
    return data;
}


export async function getBy(id: string): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function create(payload: Warehouse): Promise<Warehouse> {
    const { data } = await http.post<Warehouse, AxiosResponse>(`${controller}/`, payload)
    return data;
}

export async function update(id: number, payload: Warehouse): Promise<Warehouse> {
    const { data } = await http.put<Warehouse, AxiosResponse>(`${controller}/${id}`, payload);
    return data;
}

export async function deleted(id: number): Promise<boolean> {
    const { status } = await http.delete(`${controller}/${id}`);
    return status === 204;
}