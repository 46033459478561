import { reactive, computed } from 'vue';



const state = reactive<{ filters: number [] }>({
  filters: [],
});

const actions = {
  ActionSetFilter(filters: number[]) {
    state.filters = filters;
  }


};
const getters = {
  filters: computed(() => state.filters),
};
export { getters, state, actions };
