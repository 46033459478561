import { User } from './../../../core/model/User';
import { ref, computed } from "vue";
import { login } from "@/core/service/AuthService";

import * as storage from "./storage"
// import * as Types from "@/types";
import { Credential } from "@/core/model";
import swal from 'sweetalert2';
import { LoginResponse } from "@/core/model/LoginResponse";
let token = localStorage.getItem("token");

if (token) token = JSON.parse(String(localStorage.getItem(token)));

const state = ref<LoginResponse>({});
const idVisualizar = ref<number>(0);
const colaboradorVisualizar = ref<User>({});

function ActionSetUser(payload: LoginResponse) {
  state.value = payload;
  storage.setLocalUsuario(payload)
  storage.setLocalToken(payload.token);
}


const actions = {
  login: async (credentials: Credential) => {
    try {
      const user = await login(credentials);
      ActionSetUser(user);

    } catch (error: any) {
      const message = error?.response?.data?.message;
      if (message == 'invalid_credentials')
        swal.fire({
          icon: "warning",
          text: "Informações de usuário ou senha incorretas",
          title: "Atenção"
        });
      throw new Error("Informações de usuário ou senha incorretas")
    }
  },
  setUser: async (payload: any) => {
    ActionSetUser(payload);
  },
  setColaboradorVisualizar: (colab: User) => {
    idVisualizar.value = colab.id ?? 0;
    colaboradorVisualizar.value = colab;
    storage.setLocalColab(colab);
  },
  removeColaboradorVisualizar: () => {
    storage.deleteLocalColab();
  },
  logout: () => {

    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    state.value = {};
    storage.deleteLocalToken();
    storage.deleteLocalUsuario();
  },

  // forgotPwd: async (email: string) => {
  //   const data = await resetPassword(email);
  //   return data;
  // },

  ActionCheckToken() {

    try {
      const token = storage.getLocalToken();
      if (state.value.token) {
        return Promise.resolve(state.value.token);
      } else if (!token) {
        return this.logout()
      } else {
        ActionSetUser(storage.getLocalUsuario()); ''
        return Promise.resolve(token);
      }
    } catch (error) {
      actions.logout();
    }

  },
};
const getters = {
  getStatusLoggedIn: computed(() => {
    return !!(state.value && state.value.token)
  }),
  getToken: computed(() => {
    return state.value.token;
  }),
  getUser: computed(() => {
    return state.value;
  }),
  isMaster: computed(() => {
    return state.value.user?.is_master == 1 ?? false;
  }),
  isAdmin: computed(() => {
    return state.value.user?.is_admin == 1 ?? false;
  }),
  isMasterOrAdmin: computed(() => {
    return state.value.user?.is_master == 1 || state.value.user?.is_admin == 1;
  }),
  getIdAvaliar: computed(() => {

    if (!idVisualizar.value) {

      const colab = storage.getLocalColab();

      if (colab) idVisualizar.value = colab.id;
    }


    return idVisualizar.value != 0 ? idVisualizar.value : state.value.user?.id;
  }),
  getColabAvaliar: computed(() => {

    if (!idVisualizar.value) {

      const colab = storage.getLocalColab();

      if (colab) {
        idVisualizar.value = colab.id;
        colaboradorVisualizar.value = colab;
      }
    }
    return idVisualizar.value != 0 ? colaboradorVisualizar.value : state.value.user;
  })

};
export { getters, state, actions, idVisualizar, colaboradorVisualizar };
