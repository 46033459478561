import { AxiosResponse } from 'axios'
import { http } from '../api'
import { Brand } from '../model'

const controller = 'brands'

export async function getPaginate(page?: number, size?: number, name?: string, isExport?: boolean): Promise<any> {



    const { data } = await http.get<any, AxiosResponse>(`${controller}/?u=u${page ? `&page=${page}` : ''}${size ? `&page_length=${size}` : ''}${(name ? `&name=${name}` : '')}${isExport ? `&export=true` : ''}`);
    return data;
}


export async function getBy(id: string): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function create(payload: FormData): Promise<Brand> {

    const { data } = await http.post<Brand, AxiosResponse>(`${controller}/`, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    return data;
}

export async function update(id: number, payload: FormData): Promise<Brand> {

    const { data } = await http.put<Brand, AxiosResponse>(`${controller}/${id}`, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    return data;
}

export async function deleted(id: number): Promise<boolean> {

    const { status } = await http.delete(`${controller}/${id}`);

    return status === 204;
}